import React from "react"

import Layout from "../../components/layout-static"
import Seo from "../../components/seo"
import Video from "../../components/video"

import { StaticImage } from "gatsby-plugin-image"

const Page = () => {
  const metadata = {
    title: "Karen J.S. Gallagher",
    description:
      "Karen Gallagher specializes in personal injury and motor vehicle accidents, insurance defense, workers' compensation, and uninsured/underinsured motorist claims.",
    heroH1: "Karen J.S. Gallagher",
    heroImage: "photo-bg-staff.jpg",
  }
  return (
    <Layout metadata={metadata}>
      <Seo title={metadata.title} description={metadata.description} />
      <div
        id="content"
        className="flex flex-wrap container mx-auto px-4 xl:px-0"
      >
        <div className="w-full pt-8 xl:pt-0">
          <div className="mb-16">
            <h2>Karen J.S. Gallagher</h2>
            <div className="w-full md:w-1/2 mb-8 float-right">
              <Video videoSrcURL="https://www.youtube.com/embed/Mws5cEy6Kp4?rel=0" />
            </div>
            <div>
              <p className="mb-8">
                Karen Gallagher was a partner at Ouellette, Deganis , Gallagher &
                Grippe, LLC. She represents both plaintiffs and defendants in
                civil litigation matters statewide, including personal injury
                litigation; motor vehicle accident cases, insurance defense work,
                uninsured/ underinsured motorist claims, and workers’ compensation
                claims. Prior to joining the firm, she worked as a trial attorney
                at the staff law office of the Hartford Insurance Company (Edward
                M. Henfey & Associates), and Serignese, Petrone and Zipfel. She
                also worked as the Assistant to Mayor of East Hartford. Karen is a
                skillful negotiator who strives to obtain the best results for her
                clients.
              </p>
              <p className="mb-8">
                In addition to her legal career, along with her family, for many
                years she ran Hunter Hill Alpacas, and alpaca farm in Bristol.
                Although she is now back in Cheshire, her years of hands on
                experience in running her farm, and passionate interest in animals
                have proven valuable asset in this field.
              </p>
              <p className="mb-8">
                Attorney Gallagher is a graduate of Valparaiso University, and the
                University of Connecticut School of Law. She is admitted to the
                Connecticut Bar and the United States District Court District of
                Connecticut.
              </p>
            </div>
            <div class="rounded-2xl bg-base-300 shadow-lg flex mb-16 w-full sm:max-w-max mr-0 sm:mr-4 inline">
              <figure style={{ height: "150px", width: "150px" }}>
                <StaticImage
                  className="rounded-l-2xl"
                  height={"150"}
                  quality="100"
                  src="../../images/atty_Karen.jpg"
                  formats={["auto", "webp", "avif"]}
                  alt="Photo of Karen J.S. Gallagher"
                  imgStyle={{
                    borderTopLeftRadius: "1rem",
                    borderBottomLeftRadius: "1rem",
                  }}
                />
              </figure>
              <div class="px-4 pb-2 text-gray-900">
                <p className="text-xl font-bold">Karen J.S. Gallagher</p>
                <p className="">Of Counsel</p>
                <p className="">
                  <a href="tel:+1-203-272-1157">203-272-1157</a>  <strong>phone</strong>
                </p>
                <p className="">
                  203-250-1835 <strong>fax</strong>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Page
